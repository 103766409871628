import { h, FunctionComponent } from 'preact'

export const AppleIcon: FunctionComponent = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5983_4396)">
        <path
          d="M16.0864 7.4544C15.9937 7.5264 14.3584 8.4496 14.3584 10.5024C14.3584 12.8768 16.4393 13.7168 16.5016 13.7376C16.492 13.7888 16.171 14.888 15.4044 16.008C14.7209 16.9936 14.007 17.9776 12.921 17.9776C11.835 17.9776 11.5555 17.3456 10.3018 17.3456C9.0801 17.3456 8.6457 17.9984 7.65233 17.9984C6.65897 17.9984 5.96585 17.0864 5.16892 15.9664C4.24582 14.6512 3.5 12.608 3.5 10.6688C3.5 7.5584 5.51867 5.9088 7.50541 5.9088C8.56106 5.9088 9.44103 6.6032 10.1038 6.6032C10.7346 6.6032 11.7184 5.8672 12.9194 5.8672C13.3746 5.8672 15.0099 5.9088 16.0864 7.4544ZM12.3493 4.5504C12.8459 3.96 13.1973 3.1408 13.1973 2.3216C13.1973 2.208 13.1877 2.0928 13.167 2C12.3588 2.0304 11.3974 2.5392 10.8177 3.2128C10.3625 3.7312 9.93771 4.5504 9.93771 5.3808C9.93771 5.5056 9.95848 5.6304 9.96806 5.6704C10.0192 5.68 10.1022 5.6912 10.1853 5.6912C10.9103 5.6912 11.8222 5.2048 12.3493 4.5504Z"
          fill="#2B2D33"
        />
      </g>
      <defs>
        <clipPath id="clip0_5983_4396">
          <rect
            width="13"
            height="16"
            fill="white"
            transform="translate(3.5 2)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
