import {
  h,
  createContext,
  FunctionComponent,
  ComponentType,
  ComponentChildren,
  Fragment,
} from 'preact'
import { useContext, useEffect, useState } from 'preact/compat'
import { parseTags } from '~utils'
import { createDefaultPolyglot, updatePolyglot } from './polyglot'
import type { WithLocalisedProps } from '~core/localisation/types'
import type {
  SupportedLanguages,
  LocaleConfig,
  TranslatedTagParser,
  TranslatedTagParserWithVariable,
} from './types'
import Spinner from 'components/Spinner'
import { deviceLanguages, getDirection, getSupportLanguage } from './util'
import { defaultLocaleTag } from './languages'
import { Logger } from '~core/Logger'
import { sendEvent } from 'Tracker'

const logger = new Logger({ labels: ['localisation'] })

type ProviderProps = {
  language?: SupportedLanguages | LocaleConfig
}

const formatLanguageCode = (languageCode?: string): string | undefined => {
  if (!languageCode) {
    return languageCode
  }
  const splits = languageCode.toLowerCase().split(/[_-]/g)
  if (splits[0] === splits[1]) {
    return splits[0]
  }
  return languageCode
}

const trackLanguage = (
  language: ProviderProps['language'],
  supportedLanguage: ProviderProps['language']
) => {
  const init_language =
    typeof language === 'object'
      ? language?.locale || 'undetermined'
      : formatLanguageCode(language)

  const device_languages = (
    deviceLanguages?.map((i) => i.replace('-', '_')) || []
  )
    .map(formatLanguageCode)
    .join(',')

  const displayed_language =
    typeof supportedLanguage === 'object'
      ? supportedLanguage.locale
      : formatLanguageCode(supportedLanguage)

  const custom_languages =
    typeof language === 'object' ? language.locale || 'undetermined' : undefined

  sendEvent('language_displayed', {
    dont_format_properties: true,
    init_language,
    device_languages,
    displayed_language,
    custom_languages,
  })
}

export const LocaleContext = createContext<WithLocalisedProps | undefined>(
  undefined
)

export const LocaleProvider: FunctionComponent<ProviderProps> = ({
  language,
  children,
}) => {
  const [initialLanguage, setInitialLanguage] = useState(language)
  const supportedLanguage = getSupportLanguage(initialLanguage)
  const direction = getDirection(
    typeof language === 'object' ? language : supportedLanguage
  )

  const [polyglot, setPolyglot] = useState(createDefaultPolyglot())
  const [loading, setLoading] = useState(false)

  const translate = polyglot.t.bind(polyglot)
  const parseTranslatedTags: TranslatedTagParser = (key, handler) =>
    parseTags(translate(key), handler)

  const setLanguage = async (
    newLanguage: SupportedLanguages | LocaleConfig
  ) => {
    setLoading(true)

    const newSupportedLanguage = getSupportLanguage(newLanguage)
    let newPolyglot = createDefaultPolyglot()
    newPolyglot = await updatePolyglot(newPolyglot, newSupportedLanguage)

    logger.debug(`Use language ${newLanguage}`)

    setInitialLanguage(newLanguage)
    setPolyglot(newPolyglot)
    setLoading(false)

    trackLanguage(language, newSupportedLanguage)
  }

  useEffect(() => {
    setLanguage(supportedLanguage)
  }, [supportedLanguage])

  useEffect(() => {
    setInitialLanguage(language)
  }, [language])

  return (
    <LocaleContext.Provider
      value={{
        language: polyglot?.currentLocale,
        direction,
        translate,
        parseTranslatedTags,
        setLanguage,
        loading,
      }}
    >
      {children}
    </LocaleContext.Provider>
  )
}

type LocaleLoaderProps = {
  shouldAutoFocus?: boolean
  children: ComponentChildren
}
export const LocaleLoader = ({
  shouldAutoFocus,
  children,
}: LocaleLoaderProps) => {
  const context = useContext(LocaleContext)

  if (context?.loading) {
    return <Spinner shouldAutoFocus={shouldAutoFocus} />
  }

  return <Fragment>{children}</Fragment>
}

export const useLocales = (): WithLocalisedProps => {
  const context = useContext(LocaleContext)

  if (!context) {
    throw new Error(`LocaleContext hasn't been initialized!`)
  }

  return context
}

export const localised = <P,>(
  WrappedComponent: ComponentType<P & WithLocalisedProps>
): ComponentType<P> => {
  const LocalisedComponent: FunctionComponent<P> = (props) => (
    <LocaleContext.Consumer>
      {(injectedProps) => {
        if (injectedProps == null) {
          throw new Error(`LocaleContext hasn't been initialized!`)
        }

        return <WrappedComponent {...props} {...injectedProps} />
      }}
    </LocaleContext.Consumer>
  )

  return LocalisedComponent
}
