import { StepConfig } from '~types/steps'
import { useCallback, useEffect, useMemo, useRef, useState } from 'preact/hooks'

import { formatStep } from '../../index'
import { FlowVariants, NarrowSdkOptions, UrlsConfig } from '~types/commons'
import { StepsHook, CompleteStepValue } from '~types/routers'
import { poller, PollFunc, Engine } from '~workflow-engine'
import type {
  WorkflowResponseWithState,
  WorkflowTaskDefId,
} from '~workflow-engine/utils/WorkflowTypes'
import useUserConsent from '~contexts/useUserConsent'
import { noop } from '~utils/func'
import type { ParsedError } from '~types/api'

type WorkflowStepsState = {
  loading: boolean
  steps: StepConfig[] | undefined
  hasNextStep: boolean
  hasPreviousStep: boolean
  taskId: string | undefined
  error: string | undefined
  parsedError?: ParsedError
  isFirstCrossDeviceStep?: boolean
}

const defaultState: WorkflowStepsState = {
  loading: false,
  taskId: undefined,
  error: undefined,
  steps: undefined,
  hasNextStep: true,
  hasPreviousStep: false,
}

const captureStepTypes = new Set(['document', 'poa', 'face'])

type ArrayOfIds = Array<{ id: string; filename?: string }>

export const createWorkflowStepsHook = (
  { token, workflowRunId, ...options }: NarrowSdkOptions,
  { onfido_api_url }: UrlsConfig
): StepsHook => () => {
  const { addUserConsentStep } = useUserConsent()

  const [state, setState] = useState<WorkflowStepsState>({
    ...defaultState,
  })

  useEffect(() => {
    if (options.mobileFlow) {
      loadNextStep(noop)
      return
    }

    const steps = addUserConsentStep(options.steps) || []
    const enabledSteps = steps?.filter((step) => step.skip !== true)

    if (!enabledSteps?.length) {
      loadNextStep(noop)
      return
    }

    setState((prevState) => ({
      ...prevState,
      steps,
    }))
  }, [addUserConsentStep])

  const {
    taskId,
    loading,
    error,
    steps,
    hasNextStep,
    hasPreviousStep,
    parsedError,
    isFirstCrossDeviceStep,
  } = state

  const docData = useRef<Array<{ id: string }>>([])
  const getDocData = useCallback(() => {
    return docData.current
  }, [docData])
  const personalData = useRef<Record<string, unknown>>({})
  const getPersonalData = useCallback(() => {
    return personalData.current
  }, [personalData])
  const workflowData = useRef<Array<{ id: string }> | Record<string, unknown>>(
    []
  )

  const workflowServiceUrl = useMemo(() => `${onfido_api_url}/v3.5`, [
    onfido_api_url,
  ])

  const pollStep = useCallback((cb: () => void) => {
    if (!token) {
      throw new Error('No token provided')
    }

    if (!workflowRunId) {
      throw new Error('No workflowRunId provided')
    }

    const workflowEngine = new Engine({
      token,
      workflowRunId,
      workflowServiceUrl,
    })

    poller(async (poll: PollFunc) => {
      let workflowResponse: WorkflowResponseWithState

      try {
        workflowResponse = await workflowEngine.getWorkflow()
      } catch (e) {
        const parsedErrorObj = e as ParsedError
        setState((prevState) => ({
          ...prevState,
          loading: false,
          error:
            parsedErrorObj.response.error?.message ||
            'Workflow run ID is not set.',
          parsedError: parsedErrorObj,
        }))
        return
      }
      const { response: workflow, status } = workflowResponse

      if (status === 204) {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          hasNextStep: false,
          taskId: workflow?.id,
          steps: [formatStep('complete')],
        }))
        cb()
        return
      }

      // continue polling until an interactive task is found
      // https://onfido.atlassian.net/wiki/spaces/ORC/pages/169051941/New+studio+SDK+Task+Endpoints#GET-task
      if (!workflow?.task_def_id || Object.keys(workflow).length === 0) {
        poll(1500)
        return
      }

      const step = workflowEngine.getWorkFlowStep(
        workflow.task_def_id,
        workflow.config,
        workflow.input,
        { getDocData, getPersonalData }
      )

      if (!step) {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          error: 'Task is currently not supported.',
        }))
        return
      }

      // If the step is not displayable on mobile we display the complete step
      const formattedStep = formatStep(step)
      const stepsConfig =
        options.mobileFlow && !captureStepTypes.has(formattedStep.type)
          ? [formatStep('complete')]
          : [formattedStep]

      setState((prevState) => ({
        ...prevState,
        loading: false,
        steps: stepsConfig,
        taskId: workflow?.id,
      }))
      cb()
    })
  }, [])

  /*
    Because we send more than IDs in the confirm.ts hook, we need this step to dedupe the ids by filename.
    Input: Array of string like `ID|||Filename`.
    Output: : Array of IDs. If we find the same filename, we just overwrite the ID with the latest one.
  */
  const dedupeByFilenames = (ids: ArrayOfIds): ArrayOfIds => {
    if (!ids.length || ids.every((data) => !data.filename)) {
      return ids
    }
    const fileNameToIds: Record<string, string> = {}

    ids.forEach((data) => {
      // assign by filename. If we have the same filename twice, the latest entry wins.
      fileNameToIds[`${data.filename}`] = data.id
    })

    const result: ArrayOfIds = []
    Object.entries(fileNameToIds).forEach((val) => {
      result.push({ id: val[1] })
    })

    return result
  }

  const completeStep = useCallback(
    (data: CompleteStepValue, taskDefId?: WorkflowTaskDefId) => {
      if (Array.isArray(data)) {
        docData.current = [...docData.current, ...data]
      } else {
        personalData.current = { ...personalData.current, ...data }
      }

      if (taskDefId === 'upload_face_motion') {
        workflowData.current = docData.current
      } else {
        workflowData.current = docData.current.length
          ? docData.current
          : personalData.current
      }
    },
    []
  )

  const loadNextStep = useCallback(
    (cb: () => void, flow?: FlowVariants) => {
      if (!workflowRunId) {
        throw new Error('No token provided')
      }

      if (!token) {
        throw new Error('No token provided')
      }

      setState((prevState) => ({
        ...prevState,
        loading: true,
      }))

      // When the browser is in `crossDeviceSteps` it doesn't have to complete the step
      if (!taskId || flow === 'crossDeviceSteps') {
        pollStep(cb)
        return
      }

      if (Array.isArray(workflowData.current)) {
        workflowData.current = dedupeByFilenames(workflowData.current)
      }

      const workflowEngine = new Engine({
        token,
        workflowRunId,
        workflowServiceUrl,
      })

      workflowEngine
        .completeWorkflow(taskId, workflowData.current)
        .then(() => {
          setState((prevState) => ({
            ...prevState,
            loading: true,
            taskId: undefined,
            steps: undefined,
            hasPreviousStep: true,
          }))
          docData.current = []
          personalData.current = {}
        })
        .catch(() =>
          setState((prevState) => ({
            ...prevState,
            loading: false,
            error: 'Could not complete workflow task.',
          }))
        )
        .finally(() => pollStep(cb))
    },
    [pollStep, docData, personalData, taskId]
  )

  return {
    completeStep,
    loadNextStep,
    hasPreviousStep,
    hasNextStep,
    loading,
    steps,
    error,
    parsedError,
    isFirstCrossDeviceStep: false,
  }
}
