import { sendEvent } from 'Tracker'
import { triggerCameraPermissions } from 'components/CameraPermissions/New/utils'
import Spinner from 'components/Spinner'
import { ComponentChildren, Fragment, h } from 'preact'

import { useEffect, useState } from 'preact/hooks'
import { useSdkOptions } from '~contexts'
import useSdkConfigurationService from '~core/SdkConfiguration/useSdkConfigurationService'
import { isWebview } from '~utils/detectSystem'

type WebViewPermissionsProps = {
  children: ComponentChildren
}

export const WebViewPermissions = ({ children }: WebViewPermissionsProps) => {
  const sdkConfiguration = useSdkConfigurationService()
  const [{ onError }] = useSdkOptions()

  const checkPermissions =
    isWebview('chrome') &&
    !!sdkConfiguration?.sdk_features?.web_webview_permissions_detection_enabled

  const [status, setStatus] = useState<'checking' | 'granted' | 'denied'>(() =>
    checkPermissions ? 'checking' : 'granted'
  )

  useEffect(() => {
    if (status !== 'checking') {
      return
    }

    triggerCameraPermissions({ audio: false, video: true })
      .then(() => {
        setStatus('granted')
      })
      .catch(() => {
        setStatus('denied')
        sendEvent('permission_unknown', {
          permissions_required: 'camera',
        })
        onError &&
          onError({
            type: 'permissions_unavailable',
            message: 'Unable to retrieve or access required user permissions',
          })
      })
  }, [onError, status])

  if (status !== 'granted') {
    return <Spinner />
  }

  return <Fragment>{children}</Fragment>
}
