import { h, FunctionComponent } from 'preact'

export const SettingsFirefoxIcon: FunctionComponent = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 7C16 7.55228 15.5523 8 15 8C14.4477 8 14 7.55228 14 7C14 6.44772 14.4477 6 15 6C15.5523 6 16 6.44772 16 7ZM18 7C18 8.65685 16.6569 10 15 10C13.3431 10 12 8.65685 12 7C12 5.34315 13.3431 4 15 4C16.6569 4 18 5.34315 18 7ZM6 13C6 13.5523 5.55228 14 5 14C4.44772 14 4 13.5523 4 13C4 12.4477 4.44772 12 5 12C5.55228 12 6 12.4477 6 13ZM8 13C8 14.6569 6.65685 16 5 16C3.34315 16 2 14.6569 2 13C2 11.3431 3.34315 10 5 10C6.65685 10 8 11.3431 8 13ZM9 13C9 12.4477 9.44772 12 10 12H17C17.5523 12 18 12.4477 18 13C18 13.5523 17.5523 14 17 14H10C9.44772 14 9 13.5523 9 13ZM3 6C2.44772 6 2 6.44772 2 7C2 7.55228 2.44772 8 3 8H10C10.5523 8 11 7.55228 11 7C11 6.44772 10.5523 6 10 6H3Z"
        fill="#2B2D33"
      />
    </svg>
  )
}
