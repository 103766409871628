let _currentDimensions: SdkDimensions | undefined

export type SdkDimensions = {
  view_top: number
  view_left: number
  view_width: number
  view_height: number
}

export const setSdkDimensions = (rootElement: HTMLElement) => {
  if (!rootElement || !rootElement.children.length) {
    return
  }

  const rect = rootElement.children[0].getBoundingClientRect()
  _currentDimensions = {
    view_height: rect.height,
    view_width: rect.width,
    view_top: rect.top,
    view_left: rect.left,
  }
}

export const getSdkDimensions = () => _currentDimensions
