import { h } from 'preact'
import { localised } from '~core/localisation'
import { asyncComponent } from '~utils/components'
import Spinner from 'components/Spinner'
import { Header } from './Header'

import type { RenderFallbackProp, StepComponentProps } from '~types/routers'
import FallbackButton from 'components/Button/FallbackButton'

const Loader = localised(({ translate }) => (
  <Header
    title={translate('generic.loading')}
    // TODO: Needs translations
    // subtitle="This should take less than a minute"
  >
    <Spinner floating={false} />
  </Header>
))

const ActiveVideoOldPermissions = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "activeVideo" */ './ActiveVideo'
    ).then((module) => ({ default: module.ActiveVideoOldPermissions })),
  Loader
)

const ActiveVideoNewPermissions = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "activeVideo" */ './ActiveVideo'
    ).then((module) => ({ default: module.ActiveVideoNewPermissions })),
  Loader
)

const LazyActiveVideo = (oldPermissions: boolean) => (
  props: StepComponentProps
) => {
  const { changeFlowTo } = props
  const renderCrossDeviceFallback: RenderFallbackProp = ({ text }) => {
    return (
      <FallbackButton
        text={text}
        onClick={() => changeFlowTo('crossDeviceSteps')}
      />
    )
  }

  if (oldPermissions) {
    return (
      <ActiveVideoOldPermissions
        renderCrossDeviceFallback={renderCrossDeviceFallback}
        {...props}
      />
    )
  }

  return (
    <ActiveVideoNewPermissions
      renderCrossDeviceFallback={renderCrossDeviceFallback}
      {...props}
    />
  )
}

export const LazyActiveVideoOldPermissionsFlow = localised(
  LazyActiveVideo(true)
)

export const LazyActiveVideoNewPermissionsFlow = localised(
  LazyActiveVideo(false)
)
