import { h, Component } from 'preact'

import { HistoryRouterWrapper } from './HistoryRouter'
import type { MobileConfig } from '~types/commons'
import type { StepConfig } from '~types/steps'
import type { FlowChangeCallback, InternalRouterProps } from '~types/routers'
import Spinner from '../Spinner'
import { SdkConfigurationServiceProvider } from '~core/SdkConfiguration/useSdkConfigurationService'
import { createOptionsStepsHook } from './createOptionsStepsHook'
import { createWorkflowStepsHook } from './createWorkflowStepsHook'
import { UserConsentProvider } from '~contexts/useUserConsent'
import { localised } from '~core/localisation'
import { WithLocalisedProps } from '~core/localisation/types'
import { SupportedDocumentsProvider } from '~contexts/useSupportedDocuments'
import { WebViewPermissions } from './WebViewPermissions'

type State = {
  crossDeviceStepIndex: number
  crossDeviceSteps: StepConfig[]
}

type Props = InternalRouterProps & WithLocalisedProps

class MainRouter extends Component<Props, State> {
  useWorkflowRun = () => !!this.props.options.useWorkflow

  generateMobileConfig = (): MobileConfig => {
    const {
      documentType,
      genericDocumentType,
      idDocumentIssuingCountry,
      poaDocumentCountry,
      poaDocumentType,
      deviceHasCameraSupport,
      options,
      urls,
      analyticsSessionUuid,
      anonymousUuid,
    } = this.props

    const {
      steps,
      token,
      disableAnalytics,
      disableAnalyticsCookies,
      enterpriseFeatures,
      customUI,
      crossDeviceClientIntroProductName,
      crossDeviceClientIntroProductLogoSrc,
      workflowRunId,
      useWorkflow,
    } = options

    /*
     When the SDK.init option `language` is a string (ex: en_US), the SDK is going to match it to a supported language.
     In that case, we keep this detected value for the crossdevice. But if it's an object, we keep the original value,
     because it can contains custom translation fot the specified language.
    */
    const language =
      typeof options.language === 'object'
        ? options.language
        : this.props.language

    if (!steps) {
      throw new Error('steps not provided')
    }

    const { crossDeviceStepIndex, crossDeviceSteps } = this.state

    return {
      stepIndex: crossDeviceStepIndex,
      deviceHasCameraSupport,
      disableAnalytics,
      disableAnalyticsCookies,
      documentType,
      genericDocumentType,
      enterpriseFeatures,
      customUI: customUI || null,
      crossDeviceClientIntroProductName,
      crossDeviceClientIntroProductLogoSrc,
      idDocumentIssuingCountry,
      poaDocumentCountry,
      language,
      poaDocumentType,
      steps: crossDeviceSteps ? crossDeviceSteps : steps,
      token,
      urls,
      anonymousUuid,
      analyticsSessionUuid,
      workflowRunId,
      useWorkflow,
    }
  }

  onFlowChange: FlowChangeCallback = (
    newFlow,
    { clientStepIndex, clientSteps }
  ) => {
    if (newFlow !== 'crossDeviceSteps') {
      return
    }

    this.setState({
      crossDeviceStepIndex: clientStepIndex,
      crossDeviceSteps: clientSteps,
    })
  }

  render(): h.JSX.Element {
    const { token, options, urls } = this.props

    return (
      <SdkConfigurationServiceProvider
        overrideConfiguration={this.props.options.overrideSdkConfiguration}
        url={urls.onfido_api_url}
        token={token}
        triggerOnError={this.props.triggerOnError}
        fallback={
          <Spinner shouldAutoFocus={options.autoFocusOnInitialScreenTitle} />
        }
      >
        <UserConsentProvider
          url={urls.onfido_api_url}
          token={token}
          fallback={
            <Spinner shouldAutoFocus={options.autoFocusOnInitialScreenTitle} />
          }
        >
          <SupportedDocumentsProvider
            url={urls.onfido_api_url}
            token={token}
            hasWorkflow={this.useWorkflowRun()}
            steps={options.steps}
            fallback={
              <Spinner
                shouldAutoFocus={options.autoFocusOnInitialScreenTitle}
              />
            }
          >
            <WebViewPermissions>
              <HistoryRouterWrapper
                {...this.props}
                triggerOnError={this.props.triggerOnError}
                mobileConfig={this.generateMobileConfig()}
                onFlowChange={this.onFlowChange}
                stepIndexType="user"
                useSteps={
                  this.useWorkflowRun()
                    ? createWorkflowStepsHook(options, urls)
                    : createOptionsStepsHook(options)
                }
                fallback={
                  <Spinner
                    shouldAutoFocus={options.autoFocusOnInitialScreenTitle}
                  />
                }
              />
            </WebViewPermissions>
          </SupportedDocumentsProvider>
        </UserConsentProvider>
      </SdkConfigurationServiceProvider>
    )
  }
}

export default localised(MainRouter)
