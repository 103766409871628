import { useCallback, useEffect, useRef } from 'preact/hooks'

type MediaRecorderHook = {
  startCapture: () => void
  stopCapture: () => Promise<Blob | null>
}

const defaultWebmMimeTypes = [
  'video/webm;codecs=vp9',
  'video/webm;codecs:vp9',
  'video/webm',
  'video/webm;codecs=vp8,opus',
  'video/webm;codecs=vp8',
]

const isWebmFormatSupported = () => {
  return defaultWebmMimeTypes.some((mimeType) =>
    window.MediaRecorder?.isTypeSupported(mimeType)
  )
}

export const useMediaRecorder = (
  stream: MediaStream | null,
  webmMimeTypes: string[] = defaultWebmMimeTypes
): MediaRecorderHook => {
  const mediaRecorderRef = useRef<MediaRecorder | null>(null)

  const getSupportedFormat = useCallback(() => {
    return [...webmMimeTypes, 'video/mp4'].find((mimeType) =>
      window.MediaRecorder?.isTypeSupported(mimeType)
    )
  }, [webmMimeTypes])

  const createMediaRecorder = useCallback(() => {
    if (!window.MediaRecorder) {
      throw new ReferenceError(
        'MediaRecorder is not supported in this browser.'
      )
    }

    if (!stream) return

    mediaRecorderRef.current = new MediaRecorder(stream, {
      mimeType: getSupportedFormat(),
      audioBitsPerSecond: 128000, // Default values according to specs, but Safari doesn't
      videoBitsPerSecond: 2500000, // respect them so we need to pass them
    })
  }, [stream, getSupportedFormat])

  const startCapture = useCallback(() => {
    if (!mediaRecorderRef.current) return

    mediaRecorderRef.current.ondataavailable = null

    if (mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.stop()
    }

    createMediaRecorder()

    mediaRecorderRef.current.start()
  }, [createMediaRecorder])

  const stopCapture = useCallback((): Promise<Blob | null> => {
    if (!mediaRecorderRef.current) return Promise.resolve(null)

    if (mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.stop()

      return new Promise((resolve) => {
        if (!mediaRecorderRef.current) return resolve(null)

        mediaRecorderRef.current.ondataavailable = ({ data }: BlobEvent) => {
          if (data.size > 0) {
            const [type] = data.type.split(';', 1)
            resolve(new Blob([data], { type }))
          } else {
            resolve(null)
          }
        }
      })
    }

    return Promise.resolve(null)
  }, [])

  useEffect(() => {
    createMediaRecorder()

    return () => {
      if (mediaRecorderRef.current?.state === 'recording') {
        mediaRecorderRef.current.stop()
      }
      mediaRecorderRef.current = null
    }
  }, [createMediaRecorder])

  return { startCapture, stopCapture }
}
