import { h, FunctionComponent } from 'preact'

export const ChromeIcon: FunctionComponent = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5983_4354)">
        <path
          d="M10.0002 14.9998C12.7618 14.9998 15.0004 12.7611 15.0004 9.99948C15.0004 7.2379 12.7618 4.99916 10.0002 4.99916C7.23863 4.99916 5 7.2379 5 9.99948C5 12.7611 7.23863 14.9998 10.0002 14.9998Z"
          fill="white"
        />
        <path
          d="M5.66982 12.5008L1.34016 5.00157C0.462257 6.52168 0 8.24609 0 10.0016C0 11.7571 0.462047 13.4815 1.33984 15.0016C2.21764 16.5217 3.48021 17.784 5.00052 18.6614C6.52084 19.5388 8.24546 20.0005 10.0008 20L14.3305 12.5008V12.4996C13.892 13.2602 13.2609 13.8918 12.5008 14.3309C11.7407 14.7701 10.8784 15.0013 10.0005 15.0014C9.12268 15.0016 8.26028 14.7706 7.50005 14.3317C6.73983 13.8927 6.10859 13.2613 5.66982 12.501V12.5008Z"
          fill="#229342"
        />
        <path
          d="M14.3302 12.5005L10.0006 19.9997C11.756 20 13.4805 19.538 15.0007 18.6604C16.5209 17.7828 17.7833 16.5203 18.6609 15C19.5384 13.4797 20.0003 11.7552 20 9.99979C19.9997 8.24441 19.5372 6.52 18.6591 5H9.99989L9.99884 5.00074C10.8767 5.00032 11.7392 5.23105 12.4995 5.66971C13.2599 6.10845 13.8913 6.73953 14.3304 7.49974C14.7694 8.25993 15.0006 9.12232 15.0005 10.0002C15.0005 10.878 14.7694 11.7404 14.3303 12.5006L14.3302 12.5005Z"
          fill="#FBC116"
        />
        <path
          d="M10.0002 13.9592C12.1865 13.9592 13.9587 12.1869 13.9587 10.0005C13.9587 7.81417 12.1865 6.04199 10.0002 6.04199C7.81396 6.04199 6.04168 7.81428 6.04168 10.0005C6.04168 12.1868 7.81396 13.9592 10.0002 13.9592Z"
          fill="#1A73E8"
        />
        <path
          d="M10.0002 5.00031H18.6594C17.7819 3.48 16.5197 2.21743 14.9995 1.33963C13.4793 0.461898 11.7548 -0.000133236 9.99948 2.88205e-08C8.2441 0.000105016 6.51969 0.462365 4.99969 1.34037C3.47963 2.21832 2.21752 3.48101 1.34026 5.00147L5.66992 12.5007L5.67108 12.5013C5.23179 11.7413 5.00037 10.879 5.00009 10.0011C4.99982 9.12324 5.23069 8.26079 5.6695 7.50047C6.10824 6.74016 6.73963 6.10876 7.49984 5.66992C8.26005 5.23107 9.12252 5 10.0004 5.00021L10.0002 5.00031Z"
          fill="#E33B2E"
        />
      </g>
      <defs>
        <clipPath id="clip0_5983_4354">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
