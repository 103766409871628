import { h } from 'preact'
import style from './numberedBulletPoints.scss'

type ListItemProp = {
  text: string
}
const ListItem = ({ text }: ListItemProp) => {
  return <span className={style.numberedBullet}>{text}</span>
}

type Props = {
  items: preact.ComponentChild[]
}

export const NumberedBulletList = ({ items }: Props) => {
  return (
    <ol className={style.list}>
      {items.map((val, i) => {
        const index = i + 1
        return (
          <li className={style.listRow} key={index}>
            <ListItem text={index.toString()} /> <div>{val}</div>
          </li>
        )
      })}
    </ol>
  )
}
