import { h, FunctionComponent } from 'preact'

export const SettingsChromeIcon: FunctionComponent = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 7C4 7.55228 4.44772 8 5 8C5.55228 8 6 7.55228 6 7C6 6.44772 5.55228 6 5 6C4.44772 6 4 6.44772 4 7ZM2 7C2 8.65685 3.34315 10 5 10C6.65685 10 8 8.65685 8 7C8 5.34315 6.65685 4 5 4C3.34315 4 2 5.34315 2 7ZM14 13C14 13.5523 14.4477 14 15 14C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12C14.4477 12 14 12.4477 14 13ZM12 13C12 14.6569 13.3431 16 15 16C16.6569 16 18 14.6569 18 13C18 11.3431 16.6569 10 15 10C13.3431 10 12 11.3431 12 13ZM11 13C11 12.4477 10.5523 12 10 12H3C2.44771 12 2 12.4477 2 13C2 13.5523 2.44771 14 3 14H10C10.5523 14 11 13.5523 11 13ZM17 6C17.5523 6 18 6.44772 18 7C18 7.55228 17.5523 8 17 8H10C9.44771 8 9 7.55228 9 7C9 6.44772 9.44771 6 10 6H17Z"
        fill="var(--osdk-color-content-body)"
      />
    </svg>
  )
}
