import { h, FunctionComponent } from 'preact'
import ScreenLayout from 'components/Theme/ScreenLayout'
import { StepComponentProps } from '~types/routers'
import Spinner from 'components/Spinner'

type Props = StepComponentProps

const Loading: FunctionComponent<Props> = ({}: Props) => {
  return (
    <ScreenLayout pageId={'LoadingScreen'}>
      <Spinner />
    </ScreenLayout>
  )
}

export default Loading
