import { FunctionComponent, h } from 'preact'
import classNames from 'classnames'
import PageTitle from 'components/PageTitle'
import ScreenLayout from 'components/Theme/ScreenLayout'
import { Button } from '@onfido/castor-react'
import { localised } from '~core/localisation'
import theme from 'components/Theme/style.scss'
import style from './style.scss'
import { CameraAllowIcon } from './assets/cameraAllowIcon'
import { MicrophoneAllowIcon } from './assets/microphoneAllowIcon'
import type { WithLocalisedProps } from '~core/localisation/types'
import { Disclaimer } from 'components/Disclaimer'
import { useEffect } from 'preact/hooks'
import { CameraPermissions } from '../utils'

type PermissionsProps = {
  onClick: () => void
  onInit: () => void
  missingPermissions: CameraPermissions
}

type Props = PermissionsProps & WithLocalisedProps

type LocaleKeyType = 'camera' | 'microphone' | 'both'
type LocalisationType = {
  title: string
  subtitle: string
  disclaimer: string
  button: string
}

const locales: Record<LocaleKeyType, LocalisationType> = {
  camera: {
    title: 'permission.title_cam',
    subtitle: 'permission.subtitle_cam',
    disclaimer: 'permission.body_cam',
    button: 'permission.button_primary_cam',
  },
  microphone: {
    title: 'permission.title_mic',
    subtitle: 'permission.subtitle_mic',
    disclaimer: 'permission.body_mic',
    button: 'permission.button_primary_mic',
  },
  both: {
    title: 'permission.title_both',
    subtitle: 'permission.subtitle_both',
    disclaimer: 'permission.body_both',
    button: 'permission.button_primary_both',
  },
}

const Primer: FunctionComponent<Props> = ({
  missingPermissions,
  onClick,
  onInit,
  translate,
}) => {
  let localeKey: LocaleKeyType = 'camera'
  if (missingPermissions.audio && missingPermissions.video) {
    localeKey = 'both'
  } else if (missingPermissions.audio) {
    localeKey = 'microphone'
  } else if (missingPermissions.video) {
    localeKey = 'camera'
  }
  useEffect(() => {
    onInit()
  }, [])

  const actions = (
    <Button
      type="button"
      variant="primary"
      className={classNames(theme['button-centered'], theme['button-lg'])}
      onClick={onClick}
      data-onfido-qa="enable-camera-btn"
    >
      {translate(locales[localeKey].button)}
    </Button>
  )

  return (
    <ScreenLayout
      pageId={'Permission'}
      className={classNames(theme.fullHeightContainer)}
      actions={actions}
    >
      <PageTitle
        title={translate(locales[localeKey].title)}
        subTitle={translate(locales[localeKey].subtitle)}
      />
      <div
        className={classNames(
          style.image,
          missingPermissions.audio && missingPermissions.video
            ? style.twoImages
            : ''
        )}
      >
        {missingPermissions.video && (
          <CameraAllowIcon className={style.iconAllow} />
        )}
        {missingPermissions.audio && (
          <MicrophoneAllowIcon className={style.iconAllow} />
        )}
      </div>
      <Disclaimer text={translate(locales[localeKey].disclaimer)} />
    </ScreenLayout>
  )
}

export default localised(Primer)
