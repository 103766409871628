import { h } from 'preact'
import { IconElement } from '~types/commons'

export const MicrophoneAllowIcon: IconElement = (props) => {
  return (
    <svg
      viewBox="0 0 auto auto"
      preserveAspectRatio="xMaxYMax meet"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="24"
        y="8"
        width="32"
        height="48"
        rx="16"
        stroke="var(--osdk-color-content-body)"
        strokeWidth="4"
      />
      <path
        d="M38 88C38 89.1046 38.8954 90 40 90C41.1046 90 42 89.1046 42 88H38ZM42 88V73H38V88H42Z"
        fill="var(--osdk-color-content-body)"
      />
      <path
        d="M50 88L30 88"
        stroke="var(--osdk-color-content-body)"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M72 40C72 57.6731 57.6731 72 40 72C22.3269 72 8 57.6731 8 40"
        stroke="var(--osdk-color-content-body)"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <circle cx="82" cy="88" r="16" stroke="#51D17B" strokeWidth="4" />
      <path
        d="M77 88.0703L80.5355 91.6058L87.6066 84.5348"
        stroke="#51D17B"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
