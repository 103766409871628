import { h } from 'preact'

import { addDeviceRelatedProperties } from '~utils'
import { POA_CAPTURE_LOCALES_MAPPING } from '~utils/localesMapping'
import { randomId } from '~utils/string'

import { appendToTracking, trackException } from '../../Tracker'
import { useLocales } from '~core/localisation'
import Uploader from '../Uploader'
import { getDocumentTypeGroup } from '../DocumentSelector/documentTypes'

import withCrossDeviceWhenNoCamera from './withCrossDeviceWhenNoCamera'

import type { ImageResizeInfo, DocumentSides } from '~types/commons'
import type { WithCaptureVariantProps } from '~types/hocs'
import type { DocumentCapture } from '~types/redux'
import type {
  HandleCaptureProp,
  StepComponentDocumentProps,
} from '~types/routers'
import type { PoaTypes } from '~types/steps'
import useSdkConfigurationService from '~core/SdkConfiguration/useSdkConfigurationService'

const EXCEPTIONS = {
  DOC_TYPE_NOT_PROVIDED: 'poaDocumentType was not provided',
  CAPTURE_SIDE_NOT_PROVIDED: 'Capture side was not provided',
}

const getDocumentType = (poaDocumentType?: PoaTypes): PoaTypes => {
  if (poaDocumentType) {
    return poaDocumentType
  }

  trackException(EXCEPTIONS.DOC_TYPE_NOT_PROVIDED)
  throw new Error(EXCEPTIONS.DOC_TYPE_NOT_PROVIDED)
}

const getSide = (side?: DocumentSides): DocumentSides => {
  if (side) {
    return side
  }

  trackException(EXCEPTIONS.CAPTURE_SIDE_NOT_PROVIDED)
  throw new Error(EXCEPTIONS.CAPTURE_SIDE_NOT_PROVIDED)
}

type Props = StepComponentDocumentProps & WithCaptureVariantProps

const Document = (props: Props) => {
  const { translate } = useLocales()
  const sdkConfiguration = useSdkConfigurationService()

  const handlePhotoCapture: HandleCaptureProp = (payload) => {
    const { actions, mobileFlow, nextStep, poaDocumentType } = props

    const documentCaptureData: DocumentCapture = {
      ...payload,
      documentType: getDocumentType(poaDocumentType),
      id: payload.id || randomId(),
      method: 'poa',
      sdkMetadata: addDeviceRelatedProperties(payload.sdkMetadata, mobileFlow),
      variant: 'standard',
    }
    actions.createCapture(documentCaptureData)

    nextStep()
  }

  const handleUpload = (blob: Blob, imageResizeInfo?: ImageResizeInfo) =>
    handlePhotoCapture({
      blob,
      sdkMetadata: { captureMethod: 'html5', imageResizeInfo },
    })

  const handleError = () => {
    const { actions, requestedVariant: variant } = props
    actions.deleteCapture({ method: 'poa', variant })
  }

  const poaDocumentType = getDocumentType(props.poaDocumentType)

  const side = getSide(props.side)

  const title = translate(
    POA_CAPTURE_LOCALES_MAPPING[poaDocumentType][side]?.title || ''
  )

  const disableCrossDevice =
    !!sdkConfiguration.document_capture?.allow_disabling_cross_device &&
    props.disableCrossDevice

  const propsWithErrorHandling = {
    ...props,
    forceCrossDevice: (!disableCrossDevice && props.forceCrossDevice) ?? false,
    onError: handleError,
  }
  // Different upload types show different icons
  // return the right icon name for document
  // For document, the upload can be 'identity' or 'proof_of_address'

  // TODO: why do we try to get the document type group from the document type again. It would make much more sense
  //  to have the overall properties know about the current step and then just take the upload type from the current step

  const uploadType = getDocumentTypeGroup(poaDocumentType)
  const instructions = translate(
    POA_CAPTURE_LOCALES_MAPPING[getDocumentType(poaDocumentType)][side]?.body ||
      ''
  )

  return (
    <Uploader
      {...propsWithErrorHandling}
      disableCrossDevice={disableCrossDevice}
      uploadType={uploadType}
      onUpload={handleUpload}
      title={title}
      instructions={instructions}
      pageId={'DocumentUploader'}
    />
  )
}

export default appendToTracking(withCrossDeviceWhenNoCamera(Document))
