import { ComponentType, FunctionComponent, h } from 'preact'
import { connect } from 'react-redux'

import { buildDocumentCaptureStateKey } from '~utils/redux'
import { appendToTracking } from '../../Tracker'
import { localised } from '~core/localisation'
import { CapturePayload, RootState } from '~types/redux'
import { StepComponentProps } from '~types/routers'
import DocumentConfirm, { DocumentConfirmProps } from './DocumentConfirm'
import {
  SelfieConfirm as FaceSelfieConfirm,
  SelfieConfirmProps,
} from './SelfieConfirm'
import PoaConfirm, { PoaConfirmProps } from './PoaConfirm'

const mapStateToProps = (type: 'face' | 'poa' | 'document') => {
  return (
    { captures, globals: { isFullScreen, imageQualityRetries } }: RootState,
    { side }: DocumentConfirmProps | SelfieConfirmProps | PoaConfirmProps
  ) => {
    let capture: CapturePayload
    switch (type) {
      case 'document':
        capture = captures[
          buildDocumentCaptureStateKey({ side })
        ] as NonNullable<CapturePayload>
        break
      case 'face':
        capture = captures['face'] as NonNullable<CapturePayload>
        break
      case 'poa':
        capture = captures['poa'] as NonNullable<CapturePayload>
        break
    }
    return {
      capture,
      isFullScreen,
      imageQualityRetries,
    }
  }
}

const TrackedSelfieConfirm = appendToTracking(
  FaceSelfieConfirm,
  'confirmation'
) as FunctionComponent<SelfieConfirmProps>

const TrackedPoaConfirm = appendToTracking(
  PoaConfirm,
  'confirmation'
) as FunctionComponent<PoaConfirmProps>

const TrackedDocumentConfirm = appendToTracking(
  DocumentConfirm,
  'confirmation'
) as FunctionComponent<DocumentConfirmProps>

const LocalisedTrackedSelfieConfirm = localised(
  TrackedSelfieConfirm
) as FunctionComponent<SelfieConfirmProps>

const LocalisedTrackedPoaConfirm = localised(
  TrackedPoaConfirm
) as FunctionComponent<PoaConfirmProps>

const LocalisedTrackedDocumentConfirm = localised(
  TrackedDocumentConfirm
) as FunctionComponent<DocumentConfirmProps>

// Note: Preact and Redux types don't play nice together, hence the type cast.
const MapSelfieConfirm = (connect(mapStateToProps('face'))(
  LocalisedTrackedSelfieConfirm
) as unknown) as ComponentType<SelfieConfirmProps>

// Note: Preact and Redux types don't play nice together, hence the type cast.
const MapPoaConfirm = (connect(mapStateToProps('poa'))(
  LocalisedTrackedPoaConfirm
) as unknown) as ComponentType<PoaConfirmProps>

// Note: Preact and Redux types don't play nice together, hence the type cast.
const MapDocumentConfirm = (connect(mapStateToProps('document'))(
  LocalisedTrackedDocumentConfirm
) as unknown) as ComponentType<DocumentConfirmProps>

const PoAFrontWrapper = (props: PoaConfirmProps) => (
  <MapPoaConfirm {...props} side="front" />
)

const PoABackWrapper = (props: PoaConfirmProps) => (
  <MapPoaConfirm {...props} side="back" />
)

const DocumentFrontWrapper = (props: DocumentConfirmProps) => (
  <MapDocumentConfirm {...props} side="front" />
)

const DocumentBackWrapper = (props: DocumentConfirmProps) => (
  <MapDocumentConfirm {...props} side="back" />
)

const BaseFaceConfirm = (props: SelfieConfirmProps) => (
  <MapSelfieConfirm {...props} />
)

const DocumentFrontConfirm = appendToTracking(
  DocumentFrontWrapper,
  'front'
) as ComponentType<StepComponentProps>

const DocumentBackConfirm = appendToTracking(
  DocumentBackWrapper,
  'back'
) as ComponentType<StepComponentProps>

const SelfieConfirm = appendToTracking(
  BaseFaceConfirm,
  'selfie'
) as ComponentType<StepComponentProps>

const FaceVideoConfirm = appendToTracking(
  BaseFaceConfirm,
  'face_video'
) as ComponentType<StepComponentProps>

const PoAFrontConfirm = appendToTracking(
  PoAFrontWrapper,
  'poa'
) as ComponentType<StepComponentProps>

const PoABackConfirm = appendToTracking(
  PoABackWrapper,
  'poa'
) as ComponentType<StepComponentProps>

export {
  DocumentFrontConfirm,
  DocumentBackConfirm,
  SelfieConfirm,
  FaceVideoConfirm,
  PoAFrontConfirm,
  PoABackConfirm,
}
