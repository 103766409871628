import { ComponentChildren, Fragment, FunctionComponent, h } from 'preact'
import Recover from './Recover'
import Primer from './Primer'
import { Spinner } from '@onfido/castor-react'
import { useEffect, useState } from 'preact/hooks'
import {
  CameraPermissions,
  areCameraPermissionsGranted,
  triggerCameraPermissions,
} from './utils'

type EventHandlers = {
  onInit: () => void
  onClick: () => void
}

type Props = {
  requestedCameraPermissions: CameraPermissions
  primerHandlers?: EventHandlers
  recoverHandlers?: EventHandlers
  children: ComponentChildren
}

type ScreenType = 'primer' | 'recover'

export const WithCameraPermission: FunctionComponent<Props> = ({
  requestedCameraPermissions,
  primerHandlers,
  recoverHandlers,
  children,
}) => {
  const [alreadyGrantedPermissions, setAlreadyGrantedPermissions] = useState<
    CameraPermissions | undefined
  >()
  useEffect(() => {
    areCameraPermissionsGranted().then(setAlreadyGrantedPermissions)
  }, [areCameraPermissionsGranted])

  const [screenType, setScreenType] = useState<ScreenType>('primer')
  const click = async () => {
    try {
      await triggerCameraPermissions(requestedCameraPermissions)
      setAlreadyGrantedPermissions({ ...requestedCameraPermissions })
    } catch (error) {
      setScreenType('recover')
      console.error(error)
    }
  }
  if (!alreadyGrantedPermissions) {
    return <Spinner />
  }

  const missingPermissions: CameraPermissions = {
    audio: requestedCameraPermissions.audio && !alreadyGrantedPermissions.audio,
    video: requestedCameraPermissions.video && !alreadyGrantedPermissions.video,
  }

  const renderPermissionScreen = () => {
    switch (screenType) {
      case 'primer':
        return (
          <Primer
            missingPermissions={missingPermissions}
            onInit={() => primerHandlers?.onInit()}
            onClick={async () => {
              await click()
              primerHandlers?.onClick()
            }}
          />
        )
      case 'recover':
        return (
          <Recover
            missingPermissions={missingPermissions}
            onInit={() => recoverHandlers?.onInit()}
            onClick={() => {
              setScreenType('primer')
              recoverHandlers?.onClick()
            }}
          />
        )
    }
  }

  if (missingPermissions.audio || missingPermissions.video) {
    return renderPermissionScreen()
  }

  return <Fragment>{children}</Fragment>
}
