import Webcam from 'react-webcam'
import { canvasToBlob, MimeType } from './blob'

import type { SdkMetadata } from '~types/commons'
import type { HandleCaptureProp } from '~types/routers'

export const screenshot = (
  webcam: Webcam,
  callback: (blob: Blob, sdkMetadata: SdkMetadata) => void,
  mimeType?: MimeType
): void => {
  if (!webcam) {
    console.error('webcam is null')
    return
  }

  const video = webcam.video

  if (!video) {
    console.error('video is null')
    return
  }

  const canvas =
    webcam &&
    webcam.getCanvas({
      width: video.videoWidth,
      height: video.videoHeight,
    })

  if (!canvas) {
    console.error('webcam canvas is null')
    return
  }

  if (!webcam.stream) {
    console.error('webcam stream is null')
    return
  }

  const sdkMetadata = getDeviceInfo(webcam.stream)
  canvasToBlob(canvas, (blob) => callback(blob, sdkMetadata), mimeType)
}

export const getImageData = (webcam?: Webcam) => {
  const canvas = webcam && webcam.getCanvas()
  const video = webcam && webcam.video

  return canvas
    ?.getContext('2d')
    ?.getImageData(0, 0, video?.videoWidth || 0, video?.videoHeight || 0)
}

export const imageDataToBlob = (imageData: ImageData, mimeType?: MimeType) => {
  const w = imageData.width
  const h = imageData.height
  const canvas = document.createElement('canvas')
  canvas.width = w
  canvas.height = h
  const ctx = canvas.getContext('2d')
  ctx?.putImageData(imageData, 0, 0)
  return new Promise<Blob>((resolve) => {
    canvasToBlob(canvas, resolve, mimeType)
  })
}

export const isCameraReady = (webcam: Webcam): boolean => {
  if (!webcam) {
    return false
  }

  const canvas = webcam.getCanvas()

  if (!canvas || !canvas.width || !canvas.height) {
    return false
  }

  // Check if the canvas is still blank
  const context = canvas.getContext('2d')
  if (context) {
    const pixelBuffer = new Uint32Array(
      context.getImageData(0, 0, canvas.width, canvas.height).data.buffer
    )

    return pixelBuffer.some((color) => (color & 0x00ffffff) !== 0)
  }
  return false
}

export const getRecordedVideo = (
  webcam: Webcam,
  callback: HandleCaptureProp,
  blob: Blob | null
): void => {
  if (!blob || !webcam.stream) {
    console.error('webcam blob or stream is null')
    return
  }
  const sdkMetadata = getDeviceInfo(webcam.stream)
  callback({ blob, sdkMetadata })
}

export const getDeviceInfo = (stream?: MediaStream): SdkMetadata => {
  if (stream) {
    const videoTrack = stream.getVideoTracks()[0]
    const videoSettings = videoTrack.getSettings()
    const audioTrack = stream.getAudioTracks()[0]

    return {
      captureMethod: 'live',
      camera_name: videoTrack?.label,
      microphone_name: audioTrack?.label,
      camera_settings: {
        aspect_ratio: videoSettings?.aspectRatio,
        frame_rate: videoSettings?.frameRate,
        height: videoSettings?.height,
        width: videoSettings?.width,
      },
    }
  }

  return {}
}
