import { h } from 'preact'
import { IconElement } from '~types/commons'

export const CameraAllowIcon: IconElement = (props) => {
  return (
    <svg
      viewBox="0 0 auto auto"
      preserveAspectRatio="xMaxYMax meet"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M96 60V31.9995C96 26.4767 91.5228 21.9995 86 21.9995H81.8032C78.2147 21.9995 74.9012 20.0767 73.1208 16.9609L70.8792 13.0381C69.0988 9.92237 65.7853 7.99951 62.1968 7.99951H41.8032C38.2147 7.99951 34.9012 9.92237 33.1208 13.0381L30.8792 16.9609C29.0988 20.0766 25.7853 21.9995 22.1968 21.9995H18C12.4772 21.9995 8 26.4767 8 31.9995V77.9995C8 83.5224 12.4772 87.9995 18 87.9995H68"
        stroke="var(--osdk-color-content-body)"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <circle
        cx="52"
        cy="51.9995"
        r="20"
        stroke="var(--osdk-color-content-body)"
        strokeWidth="4"
      />
      <circle cx="96" cy="88.0005" r="16" stroke="#51D17B" strokeWidth="4" />
      <path
        d="M91 88.0708L94.5355 91.6063L101.607 84.5353"
        stroke="#51D17B"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
