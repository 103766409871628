import { Fragment, h } from 'preact'
import { isAndroid, isDesktop } from '~utils'
import detectSystem from '~utils/detectSystem'
import style from './style.scss'
import { TranslateCallback } from '@onfido/active-video-capture'
import {
  getAppleIcon,
  getBrowserIcon,
  getFirefoxSettingsIcon,
  getLockIcon,
  getWindowsIcon,
  getWindowsSettingsIcon,
  getChromeSettingsIcon,
} from './icons'
import { CameraPermissions } from '../utils'

const browser = detectSystem('browser')
const browserName = browser?.name || ''
const isChrome = browserName.includes('Chrome')
const isSafari = browserName === 'Safari'
const isFirefox = browserName.includes('Firefox') || browserName === 'Mozilla'
const isSamsung = browserName === 'Samsung Browser'
const isEdge = browserName.includes('Edge')

const os = detectSystem('os')
const osName = os?.name || ''
const isWindows = osName === 'Windows'
const isMacos = osName === 'Macintosh'

const getInputKey = (missingPermissions: CameraPermissions) =>
  missingPermissions.video && missingPermissions.audio
    ? 'both'
    : missingPermissions.video
    ? 'cam'
    : 'mic'

const getRefreshBrowserStepsInternal = (
  missingPermissions: CameraPermissions
): string[] => {
  const inputKey = getInputKey(missingPermissions)

  return [`permission_recovery.ios.browser_settings.list_item_1_${inputKey}`]
}

const getBrowserSettingInternal = (
  camera: boolean,
  microphone: boolean
): string[] => {
  if (isDesktop) {
    if (isChrome) {
      return [
        'permission_recovery.desktop_chrome.browser_settings.list_item_1',
        camera
          ? 'permission_recovery.desktop_chrome.browser_settings.list_item_2_cam'
          : '',
        microphone
          ? 'permission_recovery.desktop_chrome.browser_settings.list_item_2_mic'
          : '',

        'permission_recovery.desktop_chrome.browser_settings.list_item_3',
      ]
    }
    if (isSafari) {
      return [
        'permission_recovery.desktop_safari.browser_settings.list_item_1',
        camera
          ? 'permission_recovery.desktop_safari.browser_settings.list_item_2_cam'
          : '',
        microphone
          ? 'permission_recovery.desktop_safari.browser_settings.list_item_2_mic'
          : '',
        'permission_recovery.desktop_safari.browser_settings.list_item_3',
      ]
    }
    if (isFirefox) {
      return [
        'permission_recovery.desktop_firefox.browser_settings.list_item_1',
        camera
          ? 'permission_recovery.desktop_firefox.browser_settings.list_item_2_cam'
          : '',
        microphone
          ? 'permission_recovery.desktop_firefox.browser_settings.list_item_2_mic'
          : '',
        'permission_recovery.desktop_firefox.browser_settings.list_item_3',
      ]
    }
    if (isEdge) {
      return [
        'permission_recovery.desktop_edge.browser_settings.list_item_1',
        camera
          ? 'permission_recovery.desktop_edge.browser_settings.list_item_2_cam'
          : '',
        microphone
          ? 'permission_recovery.desktop_edge.browser_settings.list_item_2_mic'
          : '',
        'permission_recovery.desktop_edge.browser_settings.list_item_3',
      ]
    }
    return [
      'permission_recovery.desktop_generic.browser_settings.list_item_1',
      'permission_recovery.desktop_generic.browser_settings.list_item_2',
      camera
        ? 'permission_recovery.desktop_generic.browser_settings.list_item_3_cam'
        : '',
      microphone
        ? 'permission_recovery.desktop_generic.browser_settings.list_item_3_mic'
        : '',
      'permission_recovery.desktop_generic.browser_settings.list_item_4',
    ]
  }
  if (isAndroid) {
    if (isChrome) {
      return [
        'permission_recovery.android_chrome.browser_settings.list_item_1',
        'permission_recovery.android_chrome.browser_settings.list_item_2',
        camera
          ? 'permission_recovery.android_chrome.browser_settings.list_item_3_cam'
          : '',
        microphone
          ? 'permission_recovery.android_chrome.browser_settings.list_item_3_mic'
          : '',
        'permission_recovery.android_chrome.browser_settings.list_item_4',
      ]
    }
    if (isFirefox) {
      return [
        'permission_recovery.android_firefox.browser_settings.list_item_1',
        camera
          ? 'permission_recovery.android_firefox.browser_settings.list_item_2_cam'
          : '',
        microphone
          ? 'permission_recovery.android_firefox.browser_settings.list_item_2_mic'
          : '',
        'permission_recovery.android_firefox.browser_settings.list_item_3',
      ]
    }
    if (isSamsung) {
      return [
        'permission_recovery.android_samsung.browser_settings.list_item_1',
        camera
          ? 'permission_recovery.android_samsung.browser_settings.list_item_2_cam'
          : '',
        microphone
          ? 'permission_recovery.android_samsung.browser_settings.list_item_2_mic'
          : '',
        'permission_recovery.android_samsung.browser_settings.list_item_3',
      ]
    }
    return [
      'permission_recovery.android_generic.browser_settings.list_item_1',
      'permission_recovery.android_generic.browser_settings.list_item_2',
      camera
        ? 'permission_recovery.android_generic.browser_settings.list_item_3_cam'
        : '',
      microphone
        ? 'permission_recovery.android_generic.browser_settings.list_item_3_mic'
        : '',
      'permission_recovery.android_generic.browser_settings.list_item_4',
    ]
  }

  // IOS / all browsers
  if (camera && microphone) {
    return ['permission_recovery.ios.browser_settings.list_item_1_both']
  }
  if (camera) {
    return ['permission_recovery.ios.browser_settings.list_item_1_cam']
  }
  return ['permission_recovery.ios.browser_settings.list_item_1_mic']
}

const getDeviceSettingsInternal = (
  camera: boolean,
  microphone: boolean
): string[] => {
  if (isDesktop) {
    if (isWindows) {
      return [
        'permission_recovery.desktop_windows.device_settings.list_item_1',
        'permission_recovery.desktop_windows.device_settings.list_item_2',
        camera
          ? 'permission_recovery.desktop_windows.device_settings.list_item_3_cam'
          : '',
        microphone
          ? 'permission_recovery.desktop_windows.device_settings.list_item_3_mic'
          : '',
        'permission_recovery.desktop_windows.device_settings.list_item_4',
      ]
    }
    if (isMacos) {
      // On Mac Desktop, If only camera OR microphone is needed, the next instruction should be “Click Quit & Reopen in the popup and come back to this page”
      // On Mac Desktop, If both camera AND microphone are needed, the instruction after to the first permission should be “Click Later in the popup” and the instruction after the second permission should be “Click Quit & Reopen in the popup and come back to this page”
      const both = camera && microphone
      return [
        'permission_recovery.desktop_mac.device_settings.list_item_1',
        'permission_recovery.desktop_mac.device_settings.list_item_2',
        camera
          ? 'permission_recovery.desktop_mac.device_settings.list_item_3_cam'
          : '',
        both
          ? 'permission_recovery.desktop_mac.device_settings.list_item_3'
          : '',
        microphone
          ? 'permission_recovery.desktop_mac.device_settings.list_item_4_mic'
          : '',
        'permission_recovery.desktop_mac.device_settings.list_item_4',
      ]
    }
    // other oses
    return [
      'permission_recovery.desktop_generic.device_settings.list_item_1',
      'permission_recovery.desktop_generic.device_settings.list_item_2',
      camera
        ? 'permission_recovery.desktop_generic.device_settings.list_item_3_cam'
        : '',
      microphone
        ? 'permission_recovery.desktop_generic.device_settings.list_item_3_mic'
        : '',
      'permission_recovery.desktop_generic.device_settings.list_item_4',
    ]
  }
  if (isAndroid) {
    return [
      'permission_recovery.android.device_settings.list_item_1',
      'permission_recovery.android.device_settings.list_item_2',
      'permission_recovery.android.device_settings.list_item_3',
      'permission_recovery.android.device_settings.list_item_4',
      camera
        ? 'permission_recovery.android.device_settings.list_item_5_cam'
        : '',
      microphone
        ? 'permission_recovery.android.device_settings.list_item_5_mic'
        : '',
      'permission_recovery.android.device_settings.list_item_6',
    ]
  }
  // IOS
  if (isSafari) {
    return [
      'permission_recovery.ios_safari.device_settings.list_item_1',
      'permission_recovery.ios_safari.device_settings.list_item_2',
      camera
        ? 'permission_recovery.ios_safari.device_settings.list_item_3_cam'
        : '',
      microphone
        ? 'permission_recovery.ios_safari.device_settings.list_item_3_mic'
        : '',
      'permission_recovery.ios_safari.device_settings.list_item_4',
    ]
  }
  // other browsers
  return [
    'permission_recovery.ios.device_settings.list_item_1',
    'permission_recovery.ios.device_settings.list_item_2',
    camera ? 'permission_recovery.ios.device_settings.list_item_3_cam' : '',
    microphone ? 'permission_recovery.ios.device_settings.list_item_3_mic' : '',
    'permission_recovery.ios.device_settings.list_item_4',
  ]
}

export const getBrowserSettings = (
  camera: boolean,
  microphone: boolean,
  translate: TranslateCallback
) => {
  return {
    title: `permission_recovery.android${
      isFirefox ? '_firefox' : ''
    }.browser_settings.title`,
    steps: getBrowserSettingInternal(camera, microphone)
      .filter((a) => !!a)
      .map((a) => {
        // eslint-disable-next-line react/jsx-key
        return replaceStrongTag(translate(a))
      }),
  }
}

export const getDeviceSettings = (
  camera: boolean,
  microphone: boolean,
  translate: TranslateCallback
) => {
  let title = 'permission_recovery.ios.device_settings.title'

  if (isAndroid) {
    if (isFirefox) {
      title = 'permission_recovery.android_firefox.device_settings.title'
    }
    title = 'permission_recovery.android.device_settings.title'
  }

  return {
    title,
    steps: getDeviceSettingsInternal(camera, microphone)
      .filter((a) => !!a)
      .map((a) => {
        // eslint-disable-next-line react/jsx-key
        return replaceStrongTag(translate(a))
      }),
  }
}

export const getRefreshBrowserSteps = (
  missingPermissions: CameraPermissions,
  translate: TranslateCallback
) => {
  const osKey = isAndroid ? 'android_firefox' : 'ios'
  return {
    title: `permission_recovery.${osKey}.refresh_page.title`,
    steps: getRefreshBrowserStepsInternal(missingPermissions)
      .filter((a) => !!a)
      .map((a) => {
        // eslint-disable-next-line react/jsx-key
        return replaceStrongTag(translate(a))
      }),
  }
}

const icons = [
  {
    token: '<browser_icon></browser_icon>',
    icon: () =>
      getBrowserIcon({
        isChrome,
        isFirefox,
        isSafari,
        isSamsung,
        isEdge,
        browser,
      }),
  },
  { token: '<lock_icon></lock_icon>', icon: () => getLockIcon() },
  { token: '<apple_icon></apple_icon>', icon: () => getAppleIcon() },
  { token: '<windows_icon></windows_icon>', icon: () => getWindowsIcon() },
  {
    token: '<settings_icon_chrome></settings_icon_chrome>',
    icon: () => getChromeSettingsIcon(),
  },
  {
    token: '<settings_icon_windows></settings_icon_windows>',
    icon: () => getWindowsSettingsIcon(),
  },
  {
    token: '<settings_icon_firefox></settings_icon_firefox>',
    icon: () => getFirefoxSettingsIcon(),
  },
]

/**
 * input: 'Scroll through the list and tap [BROWSER / <strong>Firefox</strong>]'
 * output: <span>Scroll through the list and tap </span><span class="strong">Chrome</span> ...
 */
const replaceStrongTag = (input: string) => {
  // input
  const regex = new RegExp('<strong>(.*?)</strong>')
  const parts = input.split(regex) // the even parts are raw text. Odd parts are the words to be "Strongified".

  return parts.map((text, index) => {
    if (index % 2 === 1) {
      // odd
      return (
        <span key={index} className={style.strong}>
          {text}
        </span>
      )
    }

    for (const icon of icons) {
      if (text.indexOf(icon.token) > -1) {
        return replacePatternWithIcon(text, icon.token, icon.icon)
      }
    }

    return <span key={index}>{text}</span>
  })
}

const replacePatternWithIcon = (
  input: string,
  pattern: string,
  icon: () => JSX.Element
) => {
  const parts = input.split(pattern)
  return parts.map((text, index) => {
    if (index % 2 === 1) {
      return (
        <Fragment>
          {icon()}
          <span>{text}</span>
        </Fragment>
      )
    }
    // eslint-disable-next-line react/jsx-key
    return <span key={index}>{text}</span>
  })
}
