import { h, FunctionComponent } from 'preact'

export const SamsungIcon: FunctionComponent = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5983_4361)">
        <path
          d="M19.8174 7.01825C19.57 5.16024 18.9128 3.47667 17.7566 2.24341C16.5233 1.08722 14.8398 0.430019 12.9817 0.182555C11.2089 -0.0527392 10 0.0040559 10 0.0040559C10 0.0040559 8.79108 -0.0527392 7.01826 0.182555C5.16025 0.430019 3.47668 1.08722 2.24747 2.24341C1.08722 3.47667 0.430023 5.16024 0.182559 7.01825C-0.0527354 8.79107 0.00405972 10 0.00405972 10C0.00405972 10 -0.0527354 11.2089 0.182559 12.9817C0.430023 14.8398 1.08722 16.5233 2.24341 17.7566C3.47668 18.9168 5.16025 19.574 7.0142 19.8215C8.78702 20.0568 9.99595 20 9.99595 20C9.99595 20 11.2049 20.0568 12.9777 19.8215C14.8357 19.574 16.5193 18.9168 17.7485 17.7566C18.9087 16.5233 19.5659 14.8398 19.8093 12.9817C20.0446 11.2089 19.9878 10 19.9878 10C19.9878 10 20.0527 8.79107 19.8174 7.01825Z"
          fill="#7882FF"
        />
        <path
          d="M15.1197 8.51927C15.9108 11.359 14.2475 14.3043 11.4077 15.0953C8.56795 15.8864 5.62272 14.2231 4.83164 11.3834C4.04057 8.54361 5.70386 5.59837 8.54361 4.8073C11.3834 4.01622 14.3286 5.67951 15.1197 8.51927Z"
          fill="white"
        />
        <path
          d="M15.1197 8.51927C15.4158 9.58621 15.3671 10.6653 15.0426 11.6389C15.0183 11.716 14.9899 11.7972 14.9574 11.8742"
          fill="white"
        />
        <path
          d="M15.0345 8.51927C15.2414 9.26572 15.2819 10.0203 15.1724 10.7383Z"
          fill="white"
        />
        <path
          d="M13.5456 13.6998C12.1947 13.6998 10.5517 13.355 8.91278 12.7343C7.27384 12.1136 5.81745 11.2779 4.80731 10.3813C3.16836 8.92495 3.26978 7.79716 3.47668 7.25355C3.68357 6.70994 4.357 5.79716 6.54767 5.79716C7.89858 5.79716 9.54159 6.14198 11.1805 6.76267C12.8195 7.38336 14.2759 8.21906 15.286 9.11562C16.925 10.572 16.8235 11.6998 16.6166 12.2434C16.4138 12.787 15.7404 13.6998 13.5456 13.6998ZM6.55173 7.48884C5.53753 7.48884 5.10345 7.74036 5.05883 7.85395C5.0142 7.96754 5.17647 8.44624 5.9351 9.11967C6.78297 9.87018 8.08925 10.6166 9.51725 11.1562C10.9452 11.6998 12.4179 12.0122 13.5497 12.0122C14.5639 12.0122 14.998 11.7606 15.0426 11.6471C15.0872 11.5335 14.925 11.0548 14.1704 10.3813C13.3225 9.63083 12.0162 8.88438 10.5882 8.34482C9.15213 7.80121 7.68357 7.48884 6.55173 7.48884Z"
          fill="#4D5CC1"
        />
        <path
          d="M5.05882 7.87018L15.0345 11.6592C15.3671 10.6775 15.4199 9.59026 15.1197 8.51927C14.3286 5.67951 11.3874 4.01622 8.54361 4.8073C6.91278 5.26166 5.6714 6.42596 5.05882 7.87018Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5983_4361">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
