import { asyncComponent } from '~utils/components'
import Spinner from 'components/Spinner'

export const LazyDocumentAutoOldPermissions = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "documentAuto" */ './DocumentAuto'
    ).then((module) => ({ default: module.DocumentAutoOldPermissions })),
  Spinner
)

export const LazyDocumentAutoNewPermissions = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "documentAuto" */ './DocumentAuto'
    ).then((module) => ({ default: module.DocumentAutoNewPermissions })),
  Spinner
)
