import { h, FunctionComponent } from 'preact'
import classNames from 'classnames'
import { trackComponent } from '../../Tracker'
import { Button } from '@onfido/castor-react'
import theme from '../../components/Theme/style.scss'
import ScreenLayout from '../../components/Theme/ScreenLayout'
import PageTitle from '../../components/PageTitle'
import style from './style.scss'
import type { WithTrackingProps } from '~types/hocs'
import type { StepComponentBaseProps } from '~types/routers'
import { localised } from '~core/localisation'
import type { WithLocalisedProps } from '~core/localisation/types'
import type { RetryReason } from '~types/steps'

type RetryProps = StepComponentBaseProps & {
  reason?: RetryReason
} & WithTrackingProps &
  WithLocalisedProps

const retryReasonsTranslationKeys = {
  expired_document: 'retry_feedback_id_expired',
  generic_document_issue: 'retry_feedback_id_generic',
  unaccepted_document: 'retry_feedback_id_unaccepted',
  generic_selfie_issue: 'retry_feedback_selfie_generic',
}

const Retry: FunctionComponent<RetryProps> = ({
  reason,
  translate,
  nextStep,
}) => {
  const actions = (
    <Button
      type="button"
      variant="primary"
      className={classNames(theme['button-centered'], theme['button-lg'])}
      onClick={nextStep}
      data-onfido-qa="retry-btn"
    >
      {translate('retry_feedback.button_primary')}
    </Button>
  )

  return (
    <ScreenLayout
      actions={actions}
      className={style.container}
      pageId={'Retry'}
    >
      <PageTitle
        title={
          reason
            ? translate(`${retryReasonsTranslationKeys[reason]}.title`)
            : ''
        }
        subTitle={
          reason
            ? translate(`${retryReasonsTranslationKeys[reason]}.subtitle`)
            : ''
        }
        shouldAutoFocus={true}
      />
    </ScreenLayout>
  )
}

export default trackComponent(localised(Retry))
