import { h, FunctionComponent } from 'preact'

export const WindowsIcon: FunctionComponent = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5983_4411)">
        <path
          d="M2 4.25491L8.48855 3.37127L8.49145 9.63L2.006 9.66691L2 4.25491ZM8.48545 10.3511L8.49055 16.6153L2.00509 15.7236L2.00473 10.3091L8.48545 10.3511ZM9.272 3.25564L17.8753 2V9.55036L9.272 9.61873V3.25564ZM17.8773 10.41L17.8753 17.9264L9.272 16.7122L9.26 10.396L17.8773 10.41Z"
          fill="#2B2D33"
        />
      </g>
      <defs>
        <clipPath id="clip0_5983_4411">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
