export type CameraPermissions = {
  audio: boolean
  video: boolean
}

const stop = (stream: MediaStream) => {
  stream.getVideoTracks().forEach((track) => track.stop())
}

// This is used to trigger the built in browser popups to allow/disallow camera permissions.
// Will return an error in case the user either:
//    - clicked "no" in the browser permissions popup
//    - denied the permissions directly in the browser settings for all websites
//    - denied the permissions at OS level (user can deny Chrome on Android for instance)
export const triggerCameraPermissions = (
  permissions: CameraPermissions
): Promise<void> => {
  return navigator.mediaDevices
    .getUserMedia({
      audio: permissions.audio,
      video: permissions.video,
    })
    .then((stream) => {
      stop(stream)
    })
}

// Checks if the user has already granted the audio and video permissions. It can be used to prevent showing a useless permission screen for example.
export const areCameraPermissionsGranted = async (): Promise<CameraPermissions> => {
  try {
    const devices = await navigator.mediaDevices.enumerateDevices()
    return {
      audio: devices
        .filter((d) => `${d.kind}`.includes('audioinput'))
        .some((d) => !!d.label),
      video: devices
        .filter((d) => `${d.kind}`.includes('videoinput'))
        .some((d) => !!d.label),
    }
  } catch (error) {
    console.error(error)
    return { audio: false, video: false }
  }
}

// Checks whether the device has a camera.
export const hasCameraSupport = async (): Promise<boolean> => {
  try {
    const devices = await navigator.mediaDevices.enumerateDevices()
    return devices.some((d) => `${d.kind}`.includes('videoinput'))
  } catch (error) {
    console.error(error)
    return false
  }
}
