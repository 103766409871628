import { kebabCase } from '~utils/string'
import { UICustomizationOptions } from '~types/ui-customisation-options'
import { EnterpriseLogoCobranding } from '~types/enterprise'
import { sanitize } from 'dompurify'

export const setUICustomizations = (customUI: UICustomizationOptions): void => {
  const sdkCustomisations = Object.entries(customUI).map(
    ([property, value]) => `--osdk-${kebabCase(property)}: ${value};`
  )

  if (customUI?.googleFonts) {
    addGoogleFonts(customUI.googleFonts)
  }

  if (sdkCustomisations.length > 0) {
    const style = document.createElement('style')
    style.className = 'onfido-sdk-ui-customisation'
    style.textContent = sanitize(`:root { ${sdkCustomisations.join('\n')} }`)
    document.head.appendChild(style)
  }
}

const addGoogleFonts = (googleFonts: string | string[]) => {
  if (!Array.isArray(googleFonts)) {
    googleFonts = [googleFonts]
  }

  googleFonts.forEach((font) => {
    const fontName = font.toLowerCase().replace(/ /g, '-')
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = `${process.env.SDK_ASSETS_SRC}fonts/google-fonts/${fontName}/${fontName}.css`
    link.className = 'onfido-sdk-ui-customisation'
    document.head.appendChild(link)
  })
}

export const removeUICustomization = () => {
  const style = document.querySelector('.onfido-sdk-ui-customisation')
  if (style) {
    style.remove()
  }
}

export const setCobrandingLogos = (
  logoCobrandConfig: EnterpriseLogoCobranding
): void => {
  const style = document.createElement('style')
  style.textContent = sanitize(
    `:root { --osdk-dark-cobrand-logo-src: url(${logoCobrandConfig.darkLogoSrc}); \n --osdk-light-cobrand-logo-src: url(${logoCobrandConfig.lightLogoSrc});}`
  )
  document.head.appendChild(style)
}

export const isButtonGroupStacked = (): boolean =>
  'true' ===
  getComputedStyle(document.body)
    .getPropertyValue('--osdk-button-group-stacked')
    .trim()
