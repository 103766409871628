import { h, FunctionComponent } from 'preact'

export const LockIcon: FunctionComponent = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C7.79086 2 6 3.79086 6 6V8H5.9375C4.59131 8 3.5 9.03319 3.5 10.3077V15.6923C3.5 16.9668 4.59131 18 5.9375 18H14.0625C15.4087 18 16.5 16.9668 16.5 15.6923V10.3077C16.5 9.03319 15.4087 8 14.0625 8H14V6C14 3.79086 12.2091 2 10 2ZM12 8V6C12 4.89543 11.1046 4 10 4C8.89543 4 8 4.89543 8 6V8H12Z"
        fill="var(--osdk-color-content-body)"
      />
    </svg>
  )
}
