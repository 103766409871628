import { h, Ref } from 'preact'
import type Webcam from 'react-webcam'

import { getRecordedVideo } from '~utils/camera'
import { VIDEO_CAPTURE } from '~utils/constants'

import Timeout from '../Timeout'
import { CameraNewPermissions, CameraOldPermissions } from '../Camera'
import CameraError from '../CameraError'
import FallbackButton from '../Button/FallbackButton'
import PageTitle from '../PageTitle'
import { ToggleFullScreen } from '../FullScreen'

import type { CaptureMethods } from '~types/commons'
import type { WithTrackingProps, WithPermissionsFlowProps } from '~types/hocs'
import type {
  ErrorProp,
  HandleCaptureProp,
  RenderFallbackProp,
} from '~types/routers'
import { useCallback, useRef, useState } from 'preact/hooks'
import { useMediaRecorder } from '~webcam/useMediaRecorder'
import useSdkConfigurationService from '~core/SdkConfiguration/useSdkConfigurationService'

type VideoCaptureMethods = Exclude<CaptureMethods, 'poa' | 'activeVideo'>

type PhotoOverlayProps = {
  hasCameraError: boolean
  isRecording: boolean
}

export type VideoOverlayProps = {
  disableInteraction: boolean
  isRecording: boolean
  onStart: () => void
  onStop: () => void
} & WithPermissionsFlowProps

export type VideoCaptureProps = {
  audio?: boolean
  cameraClassName?: string
  facing?: VideoFacingModeEnum
  inactiveError: ErrorProp
  method: VideoCaptureMethods
  onRecordingStart?: () => void
  onRedo: () => void
  onVideoCapture: HandleCaptureProp
  renderFallback: RenderFallbackProp
  renderPhotoOverlay?: (props: PhotoOverlayProps) => h.JSX.Element
  renderVideoOverlay?: (props: VideoOverlayProps) => h.JSX.Element
  title?: string
  webcamRef?: Ref<Webcam | undefined>
  pageId?: string
  isUploadFallbackDisabled?: boolean
} & WithTrackingProps

const IDEAL_CAMERA_WIDTH_IN_PX = 1080 // Full HD 1080p

const RECORDING_TIMEOUT_ERRORS_MAP: Record<VideoCaptureMethods, ErrorProp> = {
  face: {
    name: 'FACE_VIDEO_TIMEOUT',
    type: 'warning',
  },
  document: {
    name: 'DOC_VIDEO_TIMEOUT',
    type: 'warning',
  },
  data: {
    name: 'PROFILE_DATA_TIMEOUT',
    type: 'warning',
  },
}

export const VideoCapture = ({
  onRecordingStart,
  onRedo,
  onVideoCapture,
  renderFallback,
  renderPhotoOverlay,
  renderVideoOverlay,
  trackScreen,
  audio,
  cameraClassName,
  facing,
  inactiveError,
  isUploadFallbackDisabled,
  method,
  pageId,
  title,
  webcamRef,
}: VideoCaptureProps) => {
  const [hasMediaStream, setHasMediaStream] = useState(false)
  const [hasBecomeInactive, setHasBecomeInactive] = useState(false)
  const [hasCameraError, setHasCameraError] = useState(false)
  const [hasRecordingTakenTooLong, setHasRecordingTakenTooLong] = useState(
    false
  )
  const [isRecording, setIsRecording] = useState(false)
  const internalWebcamRef = useRef<Webcam | null>(null)
  const { startCapture, stopCapture } = useMediaRecorder(
    internalWebcamRef.current?.stream || null
  )

  const sdkConfiguration = useSdkConfigurationService()

  const startRecording = (): void => {
    trackScreen('record_button_click')

    startCapture()
    setIsRecording(true)
    setHasBecomeInactive(false)
  }

  const stopRecording = async () => {
    setIsRecording(false)
    return await stopCapture()
  }

  const handleRecordingStart = useCallback((): void => {
    if (hasMediaStream) {
      startRecording()
      onRecordingStart && onRecordingStart()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMediaStream])

  const handleRecordingStop = async () => {
    const blob = await stopRecording()
    if (internalWebcamRef.current && !hasRecordingTakenTooLong) {
      getRecordedVideo(
        internalWebcamRef.current,
        (payload) => onVideoCapture(payload),
        blob
      )
    }
  }

  const handleMediaStream = (): void => setHasMediaStream(true)

  const handleInactivityTimeout = (): void => setHasBecomeInactive(true)

  const handleRecordingTimeout = async () => {
    setHasRecordingTakenTooLong(true)
    await stopRecording()
  }

  const handleCameraError = (): void => setHasCameraError(true)

  const handleFallbackClick = (callback?: () => void): void => {
    setHasBecomeInactive(false)
    setHasCameraError(false)
    setHasRecordingTakenTooLong(false)
    setIsRecording(false)
    // FIXME: check that the props.onRedo and callback are properly called (removed the setState callback)
    onRedo()
    callback && callback()
  }

  const renderRedoActionsFallback: RenderFallbackProp = (
    { text, type },
    callback
  ) => {
    if (type === 'timeout') {
      return String(VIDEO_CAPTURE.DOC_VIDEO_TIMEOUT)
    }

    return (
      <FallbackButton
        text={text}
        onClick={() => handleFallbackClick(callback)}
      />
    )
  }

  const renderError = (): h.JSX.Element => {
    const { [method]: recordingTimeoutError } = RECORDING_TIMEOUT_ERRORS_MAP

    const passedProps = hasRecordingTakenTooLong
      ? {
          error: recordingTimeoutError,
          hasBackdrop: true,
          renderFallback: renderRedoActionsFallback,
        }
      : {
          error: inactiveError,
          isDismissible: true,
          renderFallback,
        }

    return <CameraError trackScreen={trackScreen} {...passedProps} />
  }

  const renderInactivityTimeoutMessage = (): h.JSX.Element | null => {
    const hasError =
      hasRecordingTakenTooLong || hasCameraError || hasBecomeInactive

    if (hasError) {
      return null
    }

    const recordingTimeout =
      method === 'document'
        ? VIDEO_CAPTURE.DOC_VIDEO_TIMEOUT
        : VIDEO_CAPTURE.FACE_VIDEO_TIMEOUT

    const passedProps = {
      key: isRecording ? 'recording' : 'notRecording',
      seconds: isRecording ? recordingTimeout : VIDEO_CAPTURE.INACTIVE_TIMEOUT,
      onTimeout: isRecording ? handleRecordingTimeout : handleInactivityTimeout,
    }

    return <Timeout {...passedProps} />
  }

  const hasTimeoutError = hasBecomeInactive || hasRecordingTakenTooLong

  // Recording button should not be clickable on camera error, when recording takes too long,
  // when camera stream is not ready or when camera stream is recording
  const disableRecording =
    !hasMediaStream || hasRecordingTakenTooLong || hasCameraError || isRecording

  const useOldPermissions = !!sdkConfiguration?.sdk_features
    ?.web_disable_new_permissions_flow

  const Camera = useOldPermissions ? CameraOldPermissions : CameraNewPermissions

  return (
    <Camera
      idealCameraWidth={IDEAL_CAMERA_WIDTH_IN_PX}
      audio={!!audio}
      buttonType="video"
      containerClassName={cameraClassName}
      facing={facing}
      fallbackToDefaultWidth
      isButtonDisabled={disableRecording}
      isUploadFallbackDisabled={isUploadFallbackDisabled}
      onButtonClick={handleRecordingStart}
      onError={handleCameraError}
      onUserMedia={handleMediaStream}
      renderError={hasTimeoutError ? renderError() : null}
      renderFallback={renderFallback}
      renderVideoOverlay={
        renderVideoOverlay
          ? ({ hasGrantedPermission }) =>
              renderVideoOverlay({
                disableInteraction: isRecording
                  ? hasTimeoutError || hasCameraError
                  : !hasGrantedPermission || disableRecording,
                isRecording,
                onStart: handleRecordingStart,
                onStop: handleRecordingStop,
              })
          : undefined
      }
      renderTitle={!isRecording && title ? <PageTitle title={title} /> : null}
      trackScreen={trackScreen}
      pageId={pageId}
      webcamRef={(webcam) => {
        if (!webcam) {
          return
        }

        internalWebcamRef.current = webcam

        if (webcamRef) {
          if (typeof webcamRef === 'function') {
            webcamRef(webcam)
          } else {
            webcamRef.current = webcam
          }
        }
      }}
    >
      <ToggleFullScreen />
      {renderPhotoOverlay &&
        renderPhotoOverlay({ hasCameraError, isRecording })}
      {renderInactivityTimeoutMessage()}
    </Camera>
  )
}
