import { ComponentType, h } from 'preact'
import { trackComponent } from '../../Tracker'
import { DocumentOptions, DocumentOptionsType } from './DocumentList'
import { DocumentSelectorBase, Props } from './index'
import { upperCase } from '~utils/string'
import { PoaTypes } from '~types/steps'
import usePoASupportedCountries, {
  PoASupportedCountriesProvider,
} from '~contexts/usePoASupportedCountries'
import { map } from '~utils/object'
import { StepComponentProps } from '~types/routers'
import Spinner from 'components/Spinner'
import { PoASupportedCountry, PoaDocumentTypes } from '~types/api'

class PoADocumentSelector extends DocumentSelectorBase {
  constructor(props: Props) {
    super(props)

    this.country = upperCase(props.poaDocumentCountry?.country_alpha3) || 'GBR'
  }

  handleDocumentTypeSelected(option: DocumentOptionsType): void {
    this.props.actions.setPoADocumentType(option.type)
  }

  subTitleTranslationKey(): string {
    return 'doc_select.subtitle_poa'
  }

  titleTranslationKey(): string {
    return 'doc_select.title_poa'
  }

  getDefaultOptions(): DocumentOptions {
    const { countryList } = this.props || []

    const options = map(poaDocumentOptions, (config, key) => ({
      ...config,
      checkAvailableInCountry: (code: string) => {
        return this.checkIfCountrySupportsDocumentType(countryList, code, key)
      },
    }))

    return options as DocumentOptions
  }

  private checkIfCountrySupportsDocumentType(
    countryList: PoASupportedCountry[] | undefined,
    code: string,
    key: string
  ): boolean {
    return Object.keys(
      countryList?.find((country) => country.country_alpha3 === code)
        ?.document_types as PoaDocumentTypes
    ).includes(key as PoaTypes)
  }

  pageId(): string {
    return 'PoaDocumentSelector'
  }
}

const PoaWrapper = (props: Props) => {
  return (
    <PoASupportedCountriesProvider
      url={props.urls.onfido_api_url}
      token={props.token}
      fallback={
        <Spinner shouldAutoFocus={props.autoFocusOnInitialScreenTitle} />
      }
    >
      <PoADocumentSelection {...props} />
    </PoASupportedCountriesProvider>
  )
}

const PoADocumentSelection = (props: Props) => {
  const countries = usePoASupportedCountries()

  return <PoADocumentSelector {...props} countryList={countries} />
}

// REFACTOR: move this into the selector as soon as the
export const poaDocumentOptions: DocumentOptions = {
  bank_building_society_statement: {
    labelKey: 'doc_select.button_bank_statement',
    eStatementsKey: 'doc_select.extra_estatements_ok',
  },
  utility_bill: {
    labelKey: 'doc_select.button_bill',
    detailKey: 'doc_select.button_bill_detail',
    warningKey: 'doc_select.extra_no_mobile',
    eStatementsKey: 'doc_select.extra_estatements_ok',
  },
  council_tax: {
    labelKey: 'doc_select.button_tax_letter',
    detailKey: 'doc_select.button_tax_letter_detail',
    icon: 'icon-council-tax',
  },
  benefit_letters: {
    labelKey: 'doc_select.button_benefits_letter',
    detailKey: 'doc_select.button_benefits_letter_detail',
    icon: 'icon-letter',
  },
  address_certificate: {
    labelKey: 'doc_select.button_address_card',
    detailKey: 'doc_select.button_address_card_detail',
    icon: 'icon-address-certificate',
  },
  mortgage_statement: {
    labelKey: 'doc_select.button_mortgage_statement',
    detailKey: 'doc_select.button_mortgage_statement_detail',
    icon: 'icon-mortgage-statement',
  },
  identity_document_with_address: {
    labelKey: 'doc_select.button_identity_document_with_address',
    detailKey: 'doc_select.button_identity_document_with_address_detail',
    icon: 'icon-identity-document-with-address',
  },
  mobile_phone_bill: {
    labelKey: 'doc_select.button_mobile_phone_bill',
    detailKey: 'doc_select.button_mobile_phone_bill_detail',
    icon: 'icon-mobile-phone-bill',
  },
  general_letter: {
    labelKey: 'doc_select.button_general_letter',
    detailKey: 'doc_select.button_general_letter_detail',
    icon: 'icon-letter',
  },
  insurance_statement: {
    labelKey: 'doc_select.button_insurance_statement',
    detailKey: 'doc_select.button_insurance_statement_detail',
    icon: 'icon-insurance-statement',
  },
  pension_property_statement_letter: {
    labelKey: 'doc_select.button_pension_property_statement_letter',
    detailKey: 'doc_select.button_pension_property_statement_letter_detail',
    icon: 'icon-letter',
  },
  exchange_house_statement: {
    labelKey: 'doc_select.button_exchange_house_statement',
    detailKey: 'doc_select.button_exchange_house_statement_detail',
    icon: 'icon-exchange-house-statement',
  },
}

export default trackComponent(
  PoaWrapper,
  'type_select'
) as ComponentType<StepComponentProps>
