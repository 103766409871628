import { SdkConfiguration } from './types'

export const defaultConfiguration: SdkConfiguration = {
  experimental_features: {
    enable_multi_frame_capture: false,
    motion_experiment: {
      enabled: false,
    },
  },
  sdk_features: {
    enable_require_applicant_consents: true,
    disable_cross_device_sms: false,
    enable_in_house_analytics: true,
    enable_document_support_rules: false,
  },
  document_capture: {
    max_total_retries: 1,
    enable_js_camera_doc_capture: false,
    enable_auto_capture_doc_capture: false,
    auto_capture_timeout_ms: 20000,
  },
  motion_capture: {
    supported: true,
    sign_upload: true,
    video_settings: {
      framerate: 25,
      bitrate: 5000000,
      duration: 15000,
      webm_mime_type_preference: [],
    },
  },
  face_video_capture: {
    sign_upload: true,
    webm_mime_type_preference: [],
  },
  face_selfie_capture: {
    sign_upload: true,
  },
}
