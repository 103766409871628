import { useCallback, useEffect, useState } from 'preact/hooks'
import { StepsHook } from '~types/routers'
import { StepConfig } from '~types/steps'
import useUserConsent from '~contexts/useUserConsent'
import useMotion from '~contexts/useMotion'
import { NarrowSdkOptions } from '~types/commons'
import { useDocumentTypesAdapter } from '../RestrictedDocumentSelection'
import useSupportedDocuments from '~contexts/useSupportedDocuments'

export const createOptionsStepsHook = (
  options: NarrowSdkOptions
): StepsHook => () => {
  const { addUserConsentStep } = useUserConsent()
  const { replaceFaceWithActiveVideoStep } = useMotion()
  const { supportedDocumentsAdapter } = useSupportedDocuments()
  const { documentTypesAdapter } = useDocumentTypesAdapter()
  const [hasNextStep, setHasNextStep] = useState<boolean>(true)
  const [isFirstCrossDeviceStep, setIsFirstCrossDeviceStep] = useState<boolean>(
    false
  )
  const [steps, setSteps] = useState<StepConfig[] | undefined>(undefined)

  useEffect(() => {
    setSteps(
      addUserConsentStep(
        replaceFaceWithActiveVideoStep(
          documentTypesAdapter(supportedDocumentsAdapter(options.steps))
        )
      )
    )
  }, [
    addUserConsentStep,
    replaceFaceWithActiveVideoStep,
    documentTypesAdapter,
    supportedDocumentsAdapter,
  ])

  return {
    loadNextStep: useCallback(() => {
      setHasNextStep(false)
      setIsFirstCrossDeviceStep(false)
    }, []),
    completeStep: useCallback(() => ({}), []),
    error: undefined,
    loading: false,
    hasPreviousStep: false,
    hasNextStep,
    steps,
    isFirstCrossDeviceStep: false,
  }
}
