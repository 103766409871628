import { h, Component, ComponentType } from 'preact'

import type { WithTrackingProps, WithPermissionsFlowProps } from '~types/hocs'
import { StepOptionFace } from '~types/steps'
import { WithOnUserMediaProps } from '~types/camera'
import { WithCameraPermission } from './WithCameraPermissions'

const permissionErrors = [
  'PermissionDeniedError',
  'NotAllowedError',
  'NotFoundError',
] as const

export type PermissionError = typeof permissionErrors[number]

type Props = WithOnUserMediaProps & WithTrackingProps & WithPermissionsFlowProps

export default <P extends Props>(
  WrappedCamera: ComponentType<P>
): ComponentType<P> =>
  class WithPermissionFlow extends Component<P> {
    handleUserMedia = (stream: MediaStream) => {
      this.props.onUserMedia && this.props.onUserMedia(stream)
    }

    handleWebcamFailure = (error?: Error) => {
      if (error && permissionErrors.includes(error.name as PermissionError)) {
        return
      }

      this.props.onFailure && this.props.onFailure()
    }

    render() {
      const { trackScreen, audio } = this.props
      const { recordMotionAudio } = this.props as StepOptionFace

      return (
        <WithCameraPermission
          requestedCameraPermissions={{
            video: true,
            audio: !!(audio || recordMotionAudio),
          }}
          recoverHandlers={{
            onClick: () => {
              trackScreen('camera_access_denied_refresh_button_clicked')
            },
            onInit: () => {
              trackScreen('camera_access_denied')
            },
          }}
          primerHandlers={{
            onClick: () => {
              trackScreen('camera_access_allow_button_clicked')
            },
            onInit: () => {
              trackScreen('camera_access')
            },
          }}
        >
          <WrappedCamera
            {...this.props}
            hasGrantedPermission={true}
            onUserMedia={this.handleUserMedia}
            onFailure={this.handleWebcamFailure}
          />
        </WithCameraPermission>
      )
    }
  }
