export enum IdNumberTypes {
  SOCIAL_INSURANCE = 'social_insurance',
  IDENTITY_CARD = 'identity_card',
  PASSPORT = 'passport',
  DRIVING_LICENSE = 'driving_license',
  TAX_ID = 'tax_id',
  VOTER_ID = 'voter_id',
  OTHER = 'other',
}

type idNumberTypeDetails = {
  example: string
  maxLength: number
  format: RegExp
}

type idNumberTypesDetailsByCountries = {
  [country: string]: {
    [idType in IdNumberTypes]?: idNumberTypeDetails
  }
}

export const DEFAULT_ID_NUMBER_DETAILS: idNumberTypeDetails = {
  example: '',
  maxLength: 20,
  format: /.*/,
}

export const ID_NUMBER_TYPES_DETAILS_BY_COUNTRIES: idNumberTypesDetailsByCountries = {
  ARG: {
    other: {
      example: '10563145',
      maxLength: 8,
      format: /^\d{8}$/,
    },
  },
  AUS: {
    passport: {
      example: 'PA1234567',
      maxLength: 9,
      format: /^[a-zA-Z]{1,2}\d{7}$/,
    },
    driving_license: {
      example: '01P34U5L7',
      maxLength: 9,
      format: /^[a-zA-Z\d]{1,9}$/,
    },
    other: DEFAULT_ID_NUMBER_DETAILS,
  },
  BRA: {
    tax_id: {
      example: '000.000.000-00',
      maxLength: 14,
      format: /^\d{3}[.]?\d{3}[.]?\d{3}[-]?\d{2}$/,
    },
    other: {
      example: '00.000.000/0001-00',
      maxLength: 18,
      format: /^\d{2}[.]?\d{3}[.]?\d{3}[\/]?\d{4}[-]?\d{2}$/,
    },
  },
  CAN: {
    other: {
      example: '123456789',
      maxLength: 9,
      format: /^\d{9}$/,
    },
  },
  CHL: {
    other: {
      example: '12345678-Z',
      maxLength: 10,
      format: /^\d{8}[-]?[a-zA-Z\d]{1}$/,
    },
  },
  CHN: {
    other: {
      example: '110101195306153019',
      maxLength: 18,
      format: /^\d{17}[a-zA-Z\d]{1}$/,
    },
  },
  COL: {
    other: {
      example: '213.123.432-1',
      maxLength: 13,
      format: /^\d{3}[.]?\d{3}[.]?\d{3}[-]?\d{1}$|^\d{8,10}$/,
    },
  },
  CZE: {
    other: {
      example: '7857241234',
      maxLength: 10,
      format: /^\d{4}(0[1-9]|[12][0-9]|3[01])\d{4}$/,
    },
  },
  DNK: {
    other: {
      example: '301278-0000',
      maxLength: 11,
      format: /^(^0[1-9]|[12][0-9]|3[01])(0[1-9]|1[0-2])(\d{2})[-]?\d{4}$/,
    },
  },
  FIN: {
    other: {
      example: '311280+888Y',
      maxLength: 11,
      format: /^(^0[1-9]|[12][0-9]|3[01])(0[1-9]|1[0-2])(\d{2})[+-A]{1}\d{3}[a-zA-Z\d]{1}$/,
    },
  },
  GHA: {
    driving_license: {
      example: 'B1234567',
      maxLength: 8,
      format: /^[a-zA-Z]{1}\d{7}$/,
    },
    passport: {
      example: 'G1234567',
      maxLength: 8,
      format: /^[a-zA-Z]{1}\d{7}$/,
    },
    social_insurance: {
      example: 'C123456789000',
      maxLength: 13,
      format: /^[a-zA-Z]{1}\d{12}$/,
    },
    voter_id: {
      example: '1234567890',
      maxLength: 10,
      format: /^\d{10}$/,
    },
  },
  HKG: {
    other: {
      example: 'X123456(A)',
      maxLength: 11,
      format: /^[a-zA-Z\d]{1,2}\d{6}[(]?[1-9A]{1}[)]?$/,
    },
  },
  IND: {
    voter_id: {
      example: 'ABC1234567',
      maxLength: 10,
      format: /^[a-zA-Z]{3}\d{7}$/,
    },
    driving_license: {
      example: 'HR-0619850034761',
      maxLength: 16,
      format: /^[A-Z]{2}[-]?\d{13}$|^[A-Z]{2}\d{2}[ ]?\d{11}$/,
    },
    tax_id: {
      example: 'AAAPL1234C',
      maxLength: 10,
      format: /^[a-zA-Z]{5}\d{4}[a-zA-Z]{1}$/,
    },
  },
  IDN: {
    other: {
      example: '1234563101893476',
      maxLength: 16,
      format: /^\d{12}(?:\d{4})?$/,
    },
  },
  ITA: {
    other: {
      example: 'MSSMRA74D22A001K',
      maxLength: 16,
      format: /^[a-zA-Z\d]{16}$/,
    },
  },
  JPN: {
    other: {
      example: '12345678901',
      maxLength: 11,
      format: /^\d{11}$/,
    },
  },
  KEN: {
    identity_card: {
      example: '0123456789',
      maxLength: 10,
      format: /^\d{10}$/,
    },
    other: {
      example: '123456',
      maxLength: 6,
      format: /^\d{6}$/,
    },
    passport: {
      example: '12345678',
      maxLength: 8,
      format: /^\d{8}$/,
    },
  },
  LUX: {
    other: {
      example: '1234567890123',
      maxLength: 13,
      format: /^\d{11}$|^\d{13}$/,
    },
  },
  MYS: {
    other: {
      example: '241265-01-1234',
      maxLength: 14,
      format: /^(^0[1-9]|[12][0-9]|3[01])(0[1-9]|1[0-2])(\d{2})[-]?\d{2}[-]?\d{4}$/,
    },
  },
  MEX: {
    identity_card: {
      example: 'HEGG560427MVZRRL05',
      maxLength: 18,
      format: /^[a-zA-Z\d]{18}$/,
    },
    tax_id: {
      example: 'GOAP780710RH7',
      maxLength: 13,
      format: /^[a-zA-Z\d]{13}$/,
    },
  },
  NGA: {
    identity_card: {
      example: '12345678901',
      maxLength: 11,
      format: /^\d{11}$/,
    },
    voter_id: {
      example: '1234 5678 9012 3456 789',
      maxLength: 23,
      format: /^[a-zA-Z\d]{4}[ ]?[a-zA-Z\d]{4}[ ]?[a-zA-Z\d]{4}[ ]?[a-zA-Z\d]{4}[ ]?[a-zA-Z\d]{3}$/,
    },
    driving_license: {
      example: 'ABC-123456789',
      maxLength: 13,
      format: /^[a-zA-Z]{3}[ -]?[A-Z0-9]{9}$/,
    },
    other: {
      example: '12345678901',
      maxLength: 11,
      format: /^\d{11}$/,
    },
    tax_id: {
      example: '12345678-0001',
      maxLength: 13,
      format: /^\d{8}[-]?\d{4}$/,
    },
  },
  PHL: {
    other: {
      example: '1234-567-8912-3',
      maxLength: 15,
      format: /^\d{4}[-]?\d{3}[-]?\d{4}[-]?\d{1}$/,
    },
  },
  POL: {
    other: {
      example: '31128200102',
      maxLength: 11,
      format: /^\d{11}$/,
    },
  },
  PRT: {
    other: {
      example: '123456789',
      maxLength: 9,
      format: /^\d{9}$/,
    },
  },
  ROU: {
    other: {
      example: '1234567890123',
      maxLength: 13,
      format: /^\d{13}$/,
    },
  },
  SGP: {
    other: {
      example: 'S9162653E',
      maxLength: 9,
      format: /^[a-zA-Z]{1}[\d]{7}[a-zA-Z]{1}$/,
    },
  },
  ZAF: {
    other: {
      example: '9202204720082',
      maxLength: 13,
      format: /^\d{13}$/,
    },
  },
  ESP: {
    identity_card: {
      example: '12345678-A',
      maxLength: 10,
      format: /^\d{8}[-]?[a-zA-Z]{1}$/,
    },
    other: {
      example: 'X-1234567-A',
      maxLength: 11,
      format: /^[a-zA-Z]{1}[-]?\d{7}[-]?[a-zA-Z]{1}$/,
    },
  },
  SWE: {
    other: {
      example: '19001219-3421',
      maxLength: 13,
      format: /^\d{8}[-]?\d{4}$/,
    },
  },
  TUR: {
    other: {
      example: '12345678901',
      maxLength: 11,
      format: /^\d{11}$/,
    },
  },
  VNM: {
    other: {
      example: '123 1 12 123456',
      maxLength: 12,
      format: /^\d{9}$|^\d{12}$|^\d{3}[ ]?\d{1}[ ]?\d{2}[ ]?\d{6}$/,
    },
  },
}

export const hasIdNumber = (country: string) => {
  return country in ID_NUMBER_TYPES_DETAILS_BY_COUNTRIES
}
